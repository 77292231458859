<template>
  <div class="relative inline-block">
    <div class="
      absolute
      border-box
      block
      w-full
      h-full
      border-8
      border-current
      rounded-full
      animate-spin
      border-s-company-1
    " />
  </div>
</template>
